import React, { useState } from 'react';
import './ProjectsHome.css'

function Project(props) {
    const [index, setIndex] = useState(0);

    const cn = `project-image ${props.photos[index]}`;

    const handleNextIndex = () => {
        if (index > props.photos.length - 2) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }

    const handlePrevIndex = () => {
        if (index === 0) {
            setIndex(props.photos.length - 1)
        } else {
            setIndex(index - 1)
        }
    }
    return (
        <div className='project bc_white'>
            <div className={cn}>
                <div className='arrow prev c_w' onClick={handlePrevIndex}></div>
                <div className='arrow next c_w' onClick={handleNextIndex}></div>
            </div> 
            <div className='project-info'>
                <h3>{props.title}</h3>
                <p><b>Фасады: </b>{props.fasad}</p>
                <p><b>Корпус: </b>{props.korpus}</p>
                <p><b>Фурнитура: </b>{props.farnitura}</p>
            </div>
        </div>
    );
}

export default Project;