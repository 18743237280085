import React from 'react';
import './ServicesHome.css'

function Service(props) {
    return (
        <div className='service bc_white'>
            <img src={props.logo} alt='logo should go here' className='service-icon'/>
            <div>
                <h3>{props.title}</h3> 
                <p>{props.description}</p>
            </div>
        </div>
    );
}

export default Service;