import React, { useState } from 'react';
import './Header.css'
import { Link } from 'react-router-dom';

function Header(props) {
    const [showNav, setShowNav] = useState(false);

    const handleNav = () => {
        setShowNav(!showNav);
    }

    const Nav = {
        left: showNav ? '0' : '100vw'
    }
    const topLink = () => {
        window.scrollTo(0, 0);
    }
    return (
        <header className='bc_w'>
            <div className='header'>
                <div className='header-logo'>
                    <Link onClick={topLink} className='link' to='./'><h4 className='c_w bc_b'>MERLO</h4></Link>
                </div>
                <div className='header-nav'>
                    <Link onClick={topLink} className='link c_b' to={'/'}>Главная</Link>
                    <Link onClick={topLink} className='link c_b' to={'/about'}>О Hас</Link>
                    <Link onClick={topLink} className='link c_b' to={'/projects'}>Проекты</Link>
                    <Link onClick={topLink} className='link c_b' to={'/services'}>Услуги</Link>
                    <p onClick={() => props.handleForm()} className='link c_b'>Контакты</p>
                </div>
                <img onClick={handleNav} src='./images/icons/Menu.png' alt='menu' className='menu'/>
                <div onClick={handleNav} style={Nav} className='hidden-nav-container'>
                    <div className='hidden-nav bc_b'>
                        <Link onClick={topLink} className='link c_w' to={'/'}>Главная</Link>
                        <Link onClick={topLink} className='link c_w' to={'/about'}>О нас</Link>
                        <Link onClick={topLink} className='link c_w' to={'/projects'}>Проекты</Link>
                        <Link onClick={topLink} className='link c_w' to={'/services'}>Услуги</Link>
                        <p onClick={() => props.handleForm()} className='link c_w'>Контакты</p>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;