import React from 'react';
import Project from './Project';

function ProjectsHome(props) {
    return (
        <section className='layout projects-section'>
            <h2>{props.title}</h2>
            <p>{props.description}</p>
            <div className='projects'>
                <Project
                photos={["p1-photo_0x01", "p1-photo_0x02", "p1-photo_0x03","p1-photo_0x04", "p1-photo_0x05"]}
                title='Кухня "Голден"'
                fasad="МДФ Egger 16 мм"
                korpus="ЛДСП Egger"
                farnitura="Hettich, Boyard"
                price1="65 640 p. "
                price2="164 100 p."
                />
                <Project
                photos={["p2-photo_0x01", "p2-photo_0x02", "p2-photo_0x03", "p2-photo_0x04"]}
                title='Кухня "Гранд"'
                fasad="МДФ Egger 16 мм"
                korpus="ЛДСП SWISS KRONO"
                farnitura="Hettich"
                price1="63 920 p. "
                price2="159 800 p."
                />
                <Project
                photos={["p3-photo_0x01", "p3-photo_0x02", "p3-photo_0x03"]}
                title='Кухня "Loft"'
                fasad="МДФ Egger 16 мм"
                korpus="ЛДСП Egger"
                farnitura="Hettich"
                price1="72 960 p. "
                price2="182 400 p."
                />
                <Project
                photos={["p4-photo_0x01", "p4-photo_0x02"]}
                title='Кухня "Лазурит"'
                fasad="МДФ Egger 16 мм"
                korpus="ЛДСП Egger"
                farnitura="Boyard"
                price1="80 540 p. "
                price2="203 700 p."
                />
                <Project
                photos={["p5-photo_0x01", "p5-photo_0x02", "p5-photo_0x03", "p5-photo_0x04"]}
                title='Кухня "Бертольт"'
                fasad="МДФ Egger 16 мм"
                korpus="ЛДСП SWISS KRONO"
                farnitura="Hettich"
                price1="70 382 p. "
                price2="187 900 p."
                />
                <Project
                photos={["p6-photo_0x01", "p6-photo_0x02"]}
                title='Кухня "Айрен"'
                fasad="МДФ Egger 16 мм"
                korpus="ЛДСП Egger"
                farnitura="Hettich"
                price1="67 989 p. "
                price2="168 900 p."
                />
            </div>
            <button onClick={() => props.handleForm()} className='c_w button1 bc_r'>Расчитать проект</button>
        </section>
    );
}

export default ProjectsHome;