import React from 'react';
import './Projects.css'
import ProjectsHome from '../home/ProjectsHome';

function Projects(props) {
    return (
        <div className='projects-page bc_w'>
            <div className='main-hero-projects c_w'>
                <div className='hero-projects-container'>
                    <h1>Проекты</h1>
                    <p>Познакомьтесь с нашими реализованными проектами кухонь, где каждая деталь продумана до мелочей. Мы создаем стильные и функциональные решения, отражающие уникальные предпочтения наших клиентов.</p>
                </div>
            </div>
            <div className='projects-main'>
                <ProjectsHome
                handleForm={props.handleForm}
                />
            </div>
        </div>
    );
}

export default Projects;