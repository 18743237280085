import React from 'react';
import './Services.css'
import ServicesHome from '../home/ServicesHome';

function Services(props) {
    return (
        <div className='sercvices-page'>
            <div className='services-hero-main'>
                <div className='services-hero-container c_w'>
                    <h1>Услуги</h1>
                    <p>Обеспечьте свою кухню всем необходимым с нашим полным спектром услуг – от индивидуального проектирования до профессиональной установки и обслуживания</p>
                </div>
            </div> 
            <div className='services-main-container'>
                <ServicesHome
                handleForm={props.handleForm}
                />
            </div>
        </div>
    );
}

export default Services;