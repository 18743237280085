import React from 'react';
import './Faq.css'
import Question from './Question';

function Faq(props) {
    return (
        <section className='faq-section layout'>
            <h2>Частые вопросы</h2>
            <p className='descr'>Отвечаем на вопросы, которые нам часто задают клиенты</p>
            <Question
            question='Какие материалы используются для изготовления гарнитура?'
            answer='Наши кухонные гарнитуры изготавливаются из высококачественных материалов, таких как МДФ (плита средней плотности), ПВХ (поливинилхлорид), ЛДСП (ламинированная древесноволокнистая плита), пластик и эмаль. Эти материалы обеспечивают не только привлекательный внешний вид, но и долговечность.'
            />
            <Question
            question='Есть ли различные стили и дизайны для выбора?'
            answer='У нас есть широкий спектр стилей и дизайнов, и наше собственное производство позволяет создавать кухни практически любого размера. Выбор из более чем 900 оттенков декоров позволяет клиентам настроить гарнитур в соответствии с их индивидуальными предпочтениями'
            />
            <Question
            question='Какие опции хранения и организации предлагаются?'
            answer='Мы предлагаем разнообразные системы хранения от ведущих производителей, таких как Hettich и Kesseboehmer. Это включает в себя инновационные решения для ящиков, шкафов и выдвижных элементов, обеспечивая оптимальную организацию пространства на кухне'
            />
            <Question
            question='Каков срок доставки и установки?'
            answer='Сроки зависят от выбранного материала и сложности проекта. Например, кухни из МДФ в ПВХ могут быть готовы уже через 7 дней, в то время как гарнитуры из эмали могут потребовать до 30 дней. Мы стремимся обеспечить быструю поставку и монтаж, сохраняя при этом высокое качество. Установка занимает 1-2 дня в зависимости от сложности проекта, дата назначения сборки обговаривается индивидуально с каждым клиентом'
            />
            <Question
            question='Могу ли я изменить дизайн после отправки в производство?'
            answer='Вопросы по изменению дизайна лучше обсуждать с дизайнером или по указанному номеру из договора. В зависимости от этапа изготовления кухни, мы постараемся удовлетворить ваши пожелания. Однако, после начала производства некоторые изменения могут быть ограничены'
            />
            <Question
            question='Какова гарантия на кухонный гарнитур?'
            answer='Гарантия наших кухонных гарнитуров варьируется в зависимости от используемых материалов и фурнитуры. Мы предоставляем гарантии в пределах от 1 до 5 лет, обеспечивая наших клиентов надежность и уверенность в долгосрочной эксплуатации продукта'
            />
        </section>
    );
}

export default Faq;