import React from 'react';
import { useState, useEffect } from 'react';

function AboutSection(props) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let order1;
    let order2;
    if (windowWidth > 900) {
        order1 = props.order[0];
        order2 = props.order[1];
    } else {
        order1 = 1;
        order2 = 2;
    }

    const imageClass = `image-part ${props.image}`
    return (
        <section className='layout'>
            <div className='about-section-container bc_white'>
                <div style={{order: order1}} className={imageClass}></div>
                <div style={{order: order2}} className='info-part'>
                    <h3 className='c_r'>{props.title}</h3> 
                    <p><b>{props.bold1}</b>{props.par1}</p>
                    <p><b>{props.bold2}</b>{props.par2}</p>
                    <p><b>{props.bold3}</b>{props.par3}</p>
                    <p><b>{props.bold4}</b>{props.par4}</p>
                </div>
            </div>
        </section>
    );
}

export default AboutSection;