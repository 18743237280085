import React from 'react';
import './Chat.css';

function Chat() {
    return (
        <div style={{zIndex: "2000"}} className='main-chat-container'>
            <div className='social-container'>
                <a target='_blank' rel='noopener noreferrer' href='https://wa.me/79165058274'><img src='./images/icons/WhatsApp.png' alt='chat'/></a>
                <a target='_blank' rel='noopener noreferrer' href='https://t.me/merlo_group'><img src='./images/icons/Telegram.png' alt='chat'/></a>
            </div>
        </div>
    );
}

export default Chat;