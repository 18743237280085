import React from 'react';
import AboutSection from './AboutSection'
import './About.css'

function About(props) {
    return (
        <main className='bc_w'>
            <div className='about-hero'>
                <div className='about-hero-layer'>
                    <h1 className='c_w'>О нас</h1>
                    <p className='c_w'>Добро пожаловать на сайт нашей компании, специализирующейся на создании стильных и функциональных кухонь! Мы гордимся тем, что помогаем нашим клиентам превратить их кухонные мечты в реальность.</p>
                </div>
            </div>
            <div className='about-main-sections bc_w'>
                <AboutSection
                image='about-photo_0x01'
                title='Кто мы?'
                par1='Мы – команда талантливых дизайнеров и опытных мастеров, которые ставят перед собой целью не просто изготовить кухню, а создать пространство, в котором вы будете чувствовать себя комфортно и вдохновленно. Наша компания объединяет профессионалов с многолетним опытом работы в области дизайна интерьера и производства мебели.'
                order={[1, 2]}
                />
                <AboutSection
                image='about-photo_0x03'
                title='Что мы делаем?'
                par1='Мы предлагаем полный цикл услуг по созданию кухонь:'
                bold2='Индивидуальный дизайн: '
                par2='Наши дизайнеры разработают уникальный проект кухни, учитывая ваши предпочтения, стиль интерьера и функциональные требования.'
                bold3='Изготовление кухонной мебели: '
                par3='Наши мастера сделают кухню реальностью, используя только высококачественные материалы и современное оборудование.'
                bold4='Монтаж и установка: '
                par4='Мы гарантируем профессиональный монтаж кухни, чтобы она выглядела и функционировала идеально.'
                order={[2, 1]}
                />
                <AboutSection
                image='about-photo_0x04'
                title='Наша философия'
                par1='Мы верим, что кухня – это не просто функциональное пространство, но и сердце дома, место, где собирается вся семья. Поэтому мы стремимся создавать кухни, которые будут сочетать в себе красоту, удобство и функциональность. Наша цель – превзойти ожидания наших клиентов и помочь им создать идеальное место для приготовления пищи, общения и вдохновения.'
                order={[1, 2]}
                />
                <AboutSection
                image='about-photo_0x05'
                title='Почему выбирают нас?'
                bold1='Индивидуальный подход: '
                par1='Мы учитываем все ваши пожелания и требования, чтобы создать кухню, которая отражает вашу уникальную личность и стиль.'
                bold2='Высокое качество: '
                par2='Мы используем только лучшие материалы и тщательно контролируем каждый этап производства, чтобы обеспечить высокое качество нашей продукции.'
                bold3='Опыт и профессионализм: '
                par3='Наша команда состоит из опытных специалистов, готовых реализовать даже самые сложные проекты с максимальной точностью и качеством.'
                order={[2, 1]}
                />
                <button onClick={() => props.handleForm()} className='c_w button1 bc_r'>Расчитать проект</button>
            </div>
        </main>
    );
}

export default About;