import React from 'react';
import './SubmitForm.css'

function SubmitForm(props) {
    const display = {
        display: props.showForm ? "flex" : "none",
    }

    return (
        <div style={display} className='form-big-container'>
            <div className='form-container bc_w'>
                <div className='form-layout'>
                    <p onClick={() => props.handleForm()} className='c_b exit-form'>X</p>
                    <h3>БЫСТРЫЙ РАСЧЕТ СТОИМОСТИ</h3>
                    <p>Напишите нам в Telegram или WhatsApp, чтобы получить расчет стоимости кухонного гарнитура. Укажите размер гарнитура и свои контактные данные, и наш специалист свяжется с вами для обсуждения деталей и расчета стоимости.</p>
                    <div>
                        <a target='_blank' rel='noopener noreferrer' href='https://wa.me/79165058274'><img src='./images/icons/WhatsApp.png' alt='chat'/></a>
                        <a target='_blank' rel='noopener noreferrer' href='https://t.me/merlo_group'><img src='./images/icons/Telegram.png' alt='chat'/></a>
                    </div>
                    <h4>Пример сообщения:</h4>
                    <p>"Здравствуйте, меня зовут [Ваше имя]. Длина кухонного гарнитура вдоль стен составляет [X] метров. Мой телефон: +7 (999) 999-99-99."</p>
                </div>
            </div>
        </div>
    );
}

export default SubmitForm;