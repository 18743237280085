import React from 'react';
import './Home.css'
import ProjectsHome from './ProjectsHome';
import ServicesHome from './ServicesHome'
import Faq from './Faq';

function Home(props) {
    return (
        <main className='bc_w'>
            <div className='home-hero'>
                <div className='home-hero-layer2'>
                    <div className='layout'>
                        <h1 className='c_w'>Создайте Кухню Своей Мечты</h1>
                        <p className='c_w hero-description'>Добро пожаловать на наш сайт, где мы воплощаем ваши идеи в реальность! От индивидуального дизайна до полной установки – мы предлагаем полный спектр услуг, чтобы ваша кухня стала идеальной.</p>
                        <button onClick={() => props.handleForm()} className='c_w button1 bc_r'>Расчитать проект</button>
                    </div>
                </div>
            </div>
            <ServicesHome
            title="Услуги"
            description="Обеспечьте свою кухню всем необходимым с нашим полным спектром услуг – от индивидуального проектирования до профессиональной установки и обслуживания"
            handleForm={props.handleForm}
            />
            <ProjectsHome
            title="Проекты"
            description="Познакомьтесь с нашими реализованными проектами кухонь, где каждая деталь продумана до мелочей. Мы создаем стильные и функциональные решения, отражающие уникальные предпочтения наших клиентов."
            handleForm={props.handleForm}
            />
            <Faq/>
        </main>
    );
}

export default Home;