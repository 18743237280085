import React from 'react';
import Service from './ServiceHome'

function ServicesHome(props) {
    return (
        <section className='layout services-section'>
            <h2>{props.title}</h2>
            <p className='service-description'>{props.description}</p>
            <div className='services'>
                <Service
                logo="./images/icons/RoomPlan.png"
                title="Индивидуальный дизайн-проект"
                description="Создайте уникальный дизайн своей кухни, полностью адаптированный под ваши желания и пространство."
                />
                <Service
                logo="./images/icons/Choice.png"
                title="Выбор материалов и фурнитуры"
                description="Получите профессиональную помощь в подборе качественных материалов и аксессуаров."
                />
                <Service
                logo="./images/icons/Width.png"
                title="Замер помещения"
                description="Наш специалист приедет к вам для точного замера и консультации на месте."
                />
                <Service
                logo="./images/icons/Manager.png"
                title="Консультации по планировке"
                description="Получите советы по оптимальной планировке и выбору материалов для вашей кухни."
                />
                <Service
                logo="./images/icons/Delivery.png"
                title="Доставка мебели"
                description="Быстрая и аккуратная доставка кухонной мебели прямо до вашего дома."
                />
                <Service
                logo="./images/icons/ConstructionWorker.png"
                title="Профессиональный монтаж"
                description="Доверьте сборку и установку кухни нашим опытным мастерам."
                />
            </div>
            <button onClick={() => props.handleForm()} className='c_w button1 bc_r'>Расчитать проект</button>
        </section>
    );
}

export default ServicesHome;