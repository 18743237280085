import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import Home from './pages/home/Home';
import About from './pages/about/About'
import Projects from './pages/projects/Projects';
import Services from './pages/services/Services';
import SubmitForm from './SubmitForm'
import { useState } from 'react';
import Chat from './Chat';

function App() {
    const [showForm, setShowForm] = useState(false);

    const handleForm = () => {
        setShowForm(!showForm);
    }

    return (
    <div className="App bc_w">
        <BrowserRouter>
            <Header handleForm={handleForm}/>
            <Routes>
                <Route path="/" element={<Home handleForm={handleForm}/>}/>
                <Route path="/about" element={<About handleForm={handleForm}/>}/>
                <Route path="/projects" element={<Projects handleForm={handleForm}/>}/>
                <Route path="/services" element={<Services handleForm={handleForm}/>}/>
            </Routes>
            <Footer/>
            <SubmitForm showForm={showForm} handleForm={handleForm}/>
            <Chat/>
        </BrowserRouter>
    </div>
    ); 
}

export default App;
