import React, { useState } from 'react';

function Question(props) {
    const [answer, setAnswer] = useState(false);

    const handleAnswer = () => {
        setAnswer(!answer);
    }
    const answerStyle = {
        height: answer ? "auto" : "0"
    }
    const plusStyle = {
        transform: answer ? "rotate(45deg)" : "rotate(0deg)"
    }

    return (
        <div onClick={handleAnswer} className='question-container bc_white'>
            <div className='question'>
                <h5>{props.question}</h5> 
                <h4 className='c_r' style={plusStyle}>+</h4>
            </div>
            <p style={answerStyle}>{props.answer}</p>
        </div>
    );
}

export default Question;