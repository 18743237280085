import React from 'react';
import './Footer.css'
import { Link } from 'react-router-dom';

function Footer() {
    const topLink = () => {
        window.scrollTo(0, 0);
    }
    return (
        <footer className='bc_b c_w'>
            <div className='layout footer-container'>
                <div className='upper-footer'>
                    <h2>MERLO</h2>
                </div>
                <div className='bottom-footer'>
                    <div className='footer-column'>
                        <h4>О НАС</h4>
                        <p>Мы создаем стильные и функциональные кухни. Индивидуальные проекты, мебель на заказ и полное обслуживание – для вашего комфорта и эстетики.</p>
                    </div>
                    <div className='footer-column'>
                        <h4>НАВИГАЦИЯ</h4>
                        <Link onClick={topLink} className='link c_w' to={'/'}>Главная</Link>
                        <Link onClick={topLink} className='link c_w' to={'/about'}>О Hас</Link>
                        <Link onClick={topLink} className='link c_w' to={'/projects'}>Проекты</Link>
                        <Link onClick={topLink} className='link c_w' to={'/services'}>Услуги</Link>
                    </div>
                    <div className='footer-column'>
                        <h4>КОНТАКТНАЯ ИНФОРМАЦИЯ</h4>
                        <h5>Позвоните нам</h5>
                        <p>+7 916 505-82-74</p>
                        <h5>Напишите нам</h5>
                        <p>salikhov.merlo@gmail.com</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;